.concessions-form input[type=text] {
  border-radius: 10px;
  max-width: 350px;
  display: block;
}

@media screen and (max-width: 600px) {
  .concessions-form input {
    width: 75%;
  }
}