.quantity-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.tickets-button {
  font-size: 20px;
  width: 100%;
}

.tickets-container {
  font-size: 3rem;
  padding: 20px 10%;
}

.disabled {
  opacity: 25%;
}

.quantity-container button {
  font-size: 3rem;
  border: none;
  color: #000000;
  margin: 0 auto;
  background: none;
}