.confirmation {
  text-align: center;
}

.confirmation ion-icon {
  color: #28a745;
  font-size: 12rem;
  --ionicon-stroke-width: 2.5rem;
}

.concession-order {
  background: #e5e5e5;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  margin: 0 auto;
  padding: 20px 5%;
  width: 80%;
}

.order-row {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.order-row .poster {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  vertical-align: middle;
  width: 100%;
}

.order-row .poster img {
}

.order-row p {
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  .concession-order {
    width: 90%;
  }

  .confirmation-message {
    font-size: 44px !important;
  }
  
  .order-row p {
    font-size: 1rem;
  }
}