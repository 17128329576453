.qr-code {
  max-width: 350px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .movie-title {
    font-size: 40px !important;
  }
  .qr-code {
    max-width: 100%;
  }
}