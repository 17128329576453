.app {
  padding-bottom: 40px;
}

.main-container {
  background: #f68714;
  border-radius: 10px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin: 20px auto 0;
  padding: 20px 20px 40px;
}

.main-container h1,
.main-container h2,
.main-container h3,
.main-container h4 {
  font-family: 'Staatliches', sans-serif;
  margin: 0;
  text-align: center;
}

.main-container h1 {
  font-size: 48px;
}

.main-container h2 {
  font-size: 40px;
}

.main-container h3 {
  font-size: 36px;
}

.main-container h4 {
  font-size: 24px;
}

.main-container img {
  display: block;
}

@media screen and (max-width: 600px) {
  .main-container {
    width: 95%;
  }
}

@media screen and (min-width: 601px) {
  .main-container {
    max-width: 800px;
    width: 85%;
  }
}