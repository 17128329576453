.logo-container {
  max-width: 150px;
  margin: 20px auto 0;
}

.logo-container img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .logo-container {
    max-width: 100px !important;
  }
}