/*-------------------- RESETS --------------------*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

/*-------------------- BODY --------------------*/
body {
  min-height: 450px;
  height: 100vh;
  margin: 0 0 40px;
  padding: 0;
  background-color: #ffddbc;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

hr {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background: #ffddbc;
}

.text-accent-color {
  color: #c2202b;
}

.text-dark-color {
  color: #000000;
}

.text-light-color {
  color: #ffffff;
}

.italic {
  font-style: italic;
}

.content {
  margin: 20px 0 0;
}

.spacer {
  margin: 20px 0 0;
}

.spacer-large {
  margin: 40px 0 0;
}

.spacer-small {
  margin: 10px 0 0;
}

.movie-poster {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  margin: 0 auto 20px;
  overflow: hidden;
  width: 75%;
  cursor: pointer;
}

.movie-poster-mini {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  margin: 20px auto 10px;
  overflow: hidden;
  width: 25%;
}

.main-button {
  background: #c2202b;
  border-radius: 10px;
  border: 0;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  cursor: pointer;
  display: block;
  margin: 0 auto;
font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  max-width: 350px;
  padding: 25px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.main-button:hover {
  box-shadow: none;
}

.main-button:active {
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
}

.main-button span {
  vertical-align: middle;
}

.main-button ion-icon {
  font-size: 30px;
  margin-left: 10px;
  vertical-align: middle;
}

.secondary-button {
  background: #f68714;
  border: 5px solid #c2202b;
  color: #c2202b;
}

.concessions {
  width: 100%;
}

.container-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;
}

.container-flex .main-button, .showtimes .main-button {
  width: 75%;
}

.container-flex .item {
  margin-top: 40px;
  width: 33.33%;
}

.center-col {
  /* Lets middle column shrink/grow to available width */
  flex: 1 1 auto;
  text-align: center;
}

.left-col,
.right-col {
  /* Forces side columns to stay same width */
  flex: 0 0 50px;
}

.left-col {
  text-align: left;
}

.right-col {
  text-align: right;
}

.form-container {
  display: flex;
  margin: 0 auto;
  max-width: 390px;
  width: 100%;
  box-sizing: border-box;
}

.form-container .column-left {
  flex: 80%;
}

.form-container .column-right {
  background-color: #c2202b;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.25);
  clip-path: inset(-30px -30px -30px 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex: 20%;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.form-container .column-right:hover ion-icon {
  --ionicon-stroke-width: 50px;
}

.form-container .column-right:active {
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.25), inset 0 2px 5px rgba(0, 0, 0, 0.25);
}

input[type=text] {
  padding: 20px;
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: 0 auto;
  font-family: inherit;
  font-size: 24px;
  height: 81px;
  color: #808080;
  box-sizing: border-box;
}

.form-container input[type=text] {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  clip-path: inset(-30px 0 -30px -30px);
}



input[type=text]:focus {
  outline: none;
}

::placeholder {
  color: #d3d3d3;
  opacity: 1;
  font-family: inherit;
  font-size: inherit;
}

@media screen and (max-width: 600px) {
  .container-flex .item {
    margin-top: 40px;
    width: 100%;
  }

  .movie-poster {
    margin: 0 auto 20px;
    width: 75%;
  }

  .concessions {
    width: 75%;
  }

  .concessions-start h2, .concessions-name h2, .snacks h2, .desserts h2, .drinks h2, .concessions-repeat h2, .order-complete h2 {
    font-size: 30px !important;
  }
}
